@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
//  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// for npm material-icons package (to load local files)
$material-icons-font-path: '~material-icons/iconfont/';
@import '../node_modules/material-icons/iconfont/material-icons.scss';
@import "bootstrap/dist/css/bootstrap.css";
// @import url('https://cdn.quilljs.com/1.2.2/quill.snow.css');
// @import url('https://cdn.quilljs.com/1.2.2/quill.bubble.css');
// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "hover.css/css/hover.css";
@import "animate.css/animate.css";
@import "app/template/theme/styles/base";
@import "app/template/theme/styles/spaces";
@import "app/template/theme/styles/theme";
@import "app/template/theme/styles/libs-override";
@import "app/template/theme/styles/rtl";
@import "@ng-select/ng-select/themes/default.theme.css";

// @import '~node_modules/devextreme/dist/css/dx.light.css';
@import '~devexpress-richedit/dist/dx.richedit.css';
@import '~@devexpress/analytics-core/dist/css/dx-analytics.common.css';
@import '~@devexpress/analytics-core/dist/css/dx-analytics.light.css';
@import '~@devexpress/analytics-core/dist/css/dx-querybuilder.css';
@import '~devexpress-reporting/dist/css/dx-webdocumentviewer.css';
@import '~devexpress-reporting/dist/css/dx-reportdesigner.css';

body {
  font-size: 12px;
}
 a {
   text-decoration: none!important;
 }
.dx-row > td {
  vertical-align: middle !important;
}
.app.indigo-light .bg-primary {
  background: #283593!important;
  color: white;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.horizontal-menu-item:hover > .horizontal-sub-menu {
  max-height: 700px !important;
}

.mb-3 {
  margin-bottom: 3rem;
}

.p-20 {
  padding: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.cursor {
  cursor: pointer !important;
}

.bg-navy {
  background-color: #001F3F
}

.bg-blue {
  background-color: #0074D9
}

.bg-aqua {
  background-color: #7FDBFF
}

.bg-teal {
  background-color: #39CCCC
}

.bg-olive {
  background-color: #3D9970
}

.bg-green {
  background-color: #2ECC40
}

.bg-lime {
  background-color: #01FF70
}

.bg-yellow {
  background-color: #FFDC00
}

.bg-orange {
  background-color: #FF851B
}

.bg-red {
  background-color: #FF4136
}

.bg-fuchsia {
  background-color: #F012BE
}

.bg-purple {
  background-color: #B10DC9
}

.bg-maroon {
  background-color: #85144B
}

.bg-white {
  background-color: #fff
}

.bg-gray {
  background-color: #aaa
}

.bg-silver {
  background-color: #ddd
}

.bg-black {
  background-color: #111
}

.navy {
  color: #001F3F
}

.blue {
  color: #0074D9
}

.aqua {
  color: #7FDBFF
}

.teal {
  color: #39CCCC
}

.olive {
  color: #3D9970
}

.green {
  color: #2ECC40
}

.lime {
  color: #01FF70
}

.yellow {
  color: #FFDC00
}

.orange {
  color: #FF851B
}

.red {
  color: #FF4136
}

.fuchsia {
  color: #F012BE
}

.purple {
  color: #B10DC9
}

.maroon {
  color: #85144B
}

.white {
  color: #fff
}

.silver {
  color: #ddd
}

.gray {
  color: #aaa
}

.black {
  color: #111
}

.border--navy {
  border-color: #001F3F
}

.border--blue {
  border-color: #0074D9
}

.border--aqua {
  border-color: #7FDBFF
}

.border--teal {
  border-color: #39CCCC
}

.border--olive {
  border-color: #3D9970
}

.border--green {
  border-color: #2ECC40
}

.border--lime {
  border-color: #01FF70
}

.border--yellow {
  border-color: #FFDC00
}

.border--orange {
  border-color: #FF851B
}

.border--red {
  border-color: #FF4136
}

.border--fuchsia {
  border-color: #F012BE
}

.border--purple {
  border-color: #B10DC9
}

.border--maroon {
  border-color: #85144B
}

.border--white {
  border-color: #fff
}

.border--gray {
  border-color: #aaa
}

.border--silver {
  border-color: #ddd
}

.border--black {
  border-color: #111
}

.fill-navy {
  fill: #001F3F
}

.fill-blue {
  fill: #0074D9
}

.fill-aqua {
  fill: #7FDBFF
}

.fill-teal {
  fill: #39CCCC
}

.fill-olive {
  fill: #3D9970
}

.fill-green {
  fill: #2ECC40
}

.fill-lime {
  fill: #01FF70
}

.fill-yellow {
  fill: #FFDC00
}

.fill-orange {
  fill: #FF851B
}

.fill-red {
  fill: #FF4136
}

.fill-fuchsia {
  fill: #F012BE
}

.fill-purple {
  fill: #B10DC9
}

.fill-maroon {
  fill: #85144B
}

.fill-white {
  fill: #fff
}

.fill-gray {
  fill: #aaa
}

.fill-silver {
  fill: #ddd
}

.fill-black {
  fill: #111
}

.stroke-navy {
  stroke: #001F3F
}

.stroke-blue {
  stroke: #0074D9
}

.stroke-aqua {
  stroke: #7FDBFF
}

.stroke-teal {
  stroke: #39CCCC
}

.stroke-olive {
  stroke: #3D9970
}

.stroke-green {
  stroke: #2ECC40
}

.stroke-lime {
  stroke: #01FF70
}

.stroke-yellow {
  stroke: #FFDC00
}

.stroke-orange {
  stroke: #FF851B
}

.stroke-red {
  stroke: #FF4136
}

.stroke-fuchsia {
  stroke: #F012BE
}

.stroke-purple {
  stroke: #B10DC9
}

.stroke-maroon {
  stroke: #85144B
}

.stroke-white {
  stroke: #fff
}

.stroke-gray {
  stroke: #aaa
}

.stroke-silver {
  stroke: #ddd
}

.stroke-black {
  stroke: #111
}
.dx-datagrid.dx-datagrid-borders > .dx-datagrid-header-panel {
  padding-top: 10px;
    padding-right: 10px;
}
.mt-1 {
  margin-top:1em;
}
.mb-1 {
  margin-bottom: 1em;
}
.text-green {
  color:green;
}
.text-red{
  color: red;
}
/*
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}*/
.position-relative {
  position: relative;
}
.b-r-50 {
  border-radius: 50%!important;
  -webkit-border-radius: 50%!important;
  -moz-border-radius: 50%!important;
  -ms-border-radius: 50%!important;
  -o-border-radius: 50%!important;
}
.d-flex {
  display:flex!important;
}
.justify-space-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.app.indigo-light .mat-drawer-container {
  background-color:white!important;
}

.justify-content-center {
  justify-content: center;
}
.btn {
  min-width: 100px;
}
.dx-field {
  margin-bottom: 0.5rem!important;
  font-size: 12px!important;
  color: #000!important;
  font-weight: 500!important;
  
}
.dx-field > div > span {
  color:#d9534f;
}
.dx-texteditor.dx-editor-outlined {
  border: 1px solid #9d9b9b; 
}
.dx-texteditor-input, .dx-placeholder {
  font-size: 12px;
}
// .dx-datagrid .dx-header-filter {
//   color:lightgreen;
// }
// .dx-datagrid .dx-header-filter-active {  
//   color: rgba(149, 149, 149, 0.5)!important;  
// }
dx-button {
  min-width: 100px;
}

.card{
  width: 100%;
}

.card-header {
  background-color: #283593!important;
    color: #fff;
    font-size: 14px;
    padding: 0px 7px!important;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    h3, h5 {
      font-size: 14px!important;
    }
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}
.cursor-pointer {
  cursor: pointer;
}
.dx-toolbar-label > div {
  font-size: 14px;
  text-align: center;
}
.dx-overlay-content .dx-popup-content > .dx-template-wrapper{
  // font-size: 20px;
  text-align: center;
}
.stepper-Visible {
  height: 100%!important;
  visibility: visible!important;

}

.mat-step-label{
  width: 100% !important;
}

.mat-stepper-vertical-line::before{
  display: none !important;
}

.dx-row > td, .dx-row > tr > td {
  // border: 1px solid #f5f5f5!important;
}

.dx-datagrid .dx-datagrid-headers .dx-header-row > td, .dx-datagrid .dx-datagrid-headers .dx-header-filter {
  padding:5px;
}
.breadcrumb {
  margin-bottom: 0px;
  .mat-icon {
    padding: 0!important;
  }
}
.flex-p {
  padding:4px;
}
.order-acknowledgment-page-wrapper, .sale-order-issuance, .sales-pending-order, .process-monitoring, .test-grid, .page-wrapper {
  .dx-datagrid .dx-row > td, .dx-datagrid .dx-row > tr > td {
    .material-icons {
      font-size: 18px!important;
    }
    .mat-icon-button {
      height: 22px;
      line-height: 22px;
    }
    .mat-standard-chip {
      min-height: 22px;
    }
    // height: 40px;
  }
}
dx-data-grid {
  width: 100%;
  dx-data-grid {
    .dx-header-row > td {
      font-size: 14px!important;
        background: #636363;
        color: white;
        font-weight: 500;
    }
    dx-data-grid {
      .dx-header-row > td {
        font-size: 14px!important;
          background: #5e615e;
          color: white;
          font-weight: 500;
      }
    }
  }
}
.dx-header-row > td {
  font-size: 14px!important;
    background: #424242;
    color: white;
    font-weight: 500;
}

.page-layout{
  .dx-datagrid .dx-row > td, .dx-datagrid .dx-row > tr > td {
    padding: 10px 7px;
  }
}
#jobFormContainer, #sptFormContainer {
  .dx-overlay-content .dx-popup-content > .dx-template-wrapper {
    font-size: 14px!important;
    color:rgba(217, 83, 79, 0.4);
}
.dx-popup-content {
  .dx-template-wrapper {
    font-size: 14px!important;
    color:rgba(217, 83, 79, 0.4);
  }
}
}
.app.indigo-light .mat-input-element:disabled, .app.indigo-light .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  cursor: not-allowed;
}
.mat-checkbox-inner-container:focus {
  border: 1px solid #283593;
}
mat-checkbox {
  // color: rgb(0,178,0);
  // /deep/ .mat-checkbox-background {
  //     background-color: rgb(0,178,0);
  // }
.mat-checkbox-focused{
      .mat-ink-ripple{
        border: 1px solid #283593;
          background-color: rgba(0, 178, 0, .26);
      }
  }
}
.dx-switch-on, .dx-switch-off {
  font-size: 12px!important;
}

.panel-header{
  padding: 5px !important;
}

.search-bar form input {
  margin: 0 10px;
  font-size: 12px;
}
.text-uppercase {
  input {
    text-transform: uppercase!important;
  }
}
.panel-header {
  .toolbar {
    position: absolute;
    right: 50px;
    top: 3px;
}
}
.w-230 {
  width:230px!important
}
.hover-none:hover {
  color:inherit!important;
  background: inherit!important;
}
.total-count {
  font-size: 12px;
    background: #33a988;
    padding: 3px 7px;
    border-radius: 10px;
    color: #fff;
    margin: 0 5px;
}

.green-row{
  background: green;
  color: white;
}

.yellow-row{
  background: yellow;
  color: black;
}

.red-row{
  background: red;
  color: white;
}

.violet-row{
  background: #EE82EE;
  color: white;
}

.grey-row{
  background: #808080;
  color: white;
}

.black-row{
  background: black;
  color: white;
}

.w-300 {
  width: 300px;
}
.w-400 {
  width: 400px;
}
.w-60 {
  width:60px;
}
// .dx-tabs-wrapper{
//   background: #283593
// }
// .dx-item.dx-tab{
//   color: white;
// }
.dx-item.dx-tab.dx-state-hover, .dx-item.dx-tab.dx-tab-selected{
  // color: black;
  background: #283593;
  color: white;
}
#form-cyl-container {
    #listResults {
      .checkbox {
        cursor: pointer;
      }
  }
} 
  // .new-checkbox {
  //   padding-left: 20px;
  //   width: 20px;
  //   height: 20px;
  //   margin-top: 1px;
  //   cursor: pointer;
  //   label {
  //     display: inline-block;
  //     vertical-align: middle;
  //     position: relative;
  //     padding-left: 5px;
  //   }
  //   label::before {
  //     content: "";
  //     display: inline-block;
  //     position: absolute;
  //     width: 20px;
  //     height: 20px;
  //     left: 0;
  //     margin-left: -20px;
  //     border: 1px solid #555;
  //     border-radius: 3px;
  //     background-color: #fff;
  //     -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  //     -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  //     transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  //   }
  //   label::after {
  //     display: inline-block;
  //     position: absolute;
  //     width: 20px;
  //     height: 20px;
  //     left: 0;
  //     top: 2px;
  //     margin-left: -21px;
  //     padding-left: 3px;
  //     padding-top: 1px;
  //     font-size: 11px;
  //     color: #555555;
  //   }
  //   input[type="checkbox"]{
  //     opacity: 0;
  //     z-index: 1;
  //     width: 20px;
  //     height: 20px;
  //   }
  //   input[type="checkbox"]:checked + label::after{
  //     font-family: "FontAwesome";
  //     content: "\f00c";
  //   }
  //   .checkbox-circle label::before {
  //     border-radius: 0%;
  //   }
  //   .checkbox-inline {
  //     margin-top: 0;
  //   }
  //   .checkbox-red    input[type="checkbox"]:checked + label::after,
  //   .checkbox-yellow input[type="checkbox"]:checked + label::after,
  //   .checkbox-green  input[type="checkbox"]:checked + label::after,
  //   .checkbox-blue   input[type="checkbox"]:checked + label::after,
  //   .checkbox-black  input[type="checkbox"]:checked + label::after
  //   {color: #fff;}
  //   .checkbox-red input[type="checkbox"] + label::before
  // {background-color: #990000;  border-color: #990000;}
  
  //  .checkbox-yellow input[type="checkbox"] + label::before
  // {background-color: #face00;  border-color: #face00;}
  
  //  .checkbox-green input[type="checkbox"] + label::before
  // {background-color: #008000;  border-color: #008000;}
  
  //  .checkbox-blue input[type="checkbox"] + label::before
  // {background-color: #428bca;  border-color: #428bca;}
  
  //  .checkbox-black input[type="checkbox"] + label::before
  // {background-color: #000000;  border-color: #000000;}
  // }
  
  
  
  
  
  
  
  #listResults .checkbox.checkbox-circle label::before {
    border-radius: 0%;
  }
  
  #listResults .checkbox.checkbox-inline {
    margin-top: 0;
  }
  
  #listResults .checkbox-red    input[type="checkbox"]:checked + label::after,
  #listResults .checkbox-yellow input[type="checkbox"]:checked + label::after,
  #listResults .checkbox-green  input[type="checkbox"]:checked + label::after,
  #listResults .checkbox-blue   input[type="checkbox"]:checked + label::after,
  #listResults .checkbox-black  input[type="checkbox"]:checked + label::after
  {color: #fff;}
  
  #listResults .checkbox-red input[type="checkbox"] + label::before
  {background-color: #990000;  border-color: #990000;}
  
  #listResults .checkbox-yellow input[type="checkbox"] + label::before
  {background-color: #face00;  border-color: #face00;}
  
  #listResults .checkbox-green input[type="checkbox"] + label::before
  {background-color: #008000;  border-color: #008000;}
  
  #listResults .checkbox-blue input[type="checkbox"] + label::before
  {background-color: #428bca;  border-color: #428bca;}
  
  #listResults .checkbox-black input[type="checkbox"] + label::before
  {background-color: #000000;  border-color: #000000;}
    .dx-footer {
        position: absolute;
        bottom: 0;
        padding: 0 20px 20px 0;
        width: 100%;
        right: 0;
        border-top: 1px solid #ccc;
    }

    #dealInner, #close {
      color: #ffffff;
      display: block;
      font-size: 16px;
      // vertical-align: baseline;
      cursor: pointer;
  }
  .m-w-50 {
    min-width:50px;
  }
  .filter-position {
    position: absolute; 
    top: 180px; 
    z-index: 2;
  }
  #sphLeft , #cylLeft, #addfLeft, #sphRight, #cylRight, #addfRight{
    .dx-dropdowneditor-button {
      min-width: 25px;
      width:25px;
    }
}
.swal2-shown {
  z-index: 22222;
}

// .tickt-label-page-break{
//   display: none;
// }

.test-class{
  page-break-after: always;
}

@media print {
  /* invert the display (show/hide) properties of the main */
  /* aplication component and the printing component       */

  app-label-test {
    display: block;
  }

  .ticket-label-form, app-breadcrumb, app-horizontal-menu {
    display: none;
  }

  .test-class{
    page-break-after: always;
  }

  // .mat-toolbar{
  //   display: none;
  // }

  // .options-icon{
  //   display: none;
  // }

  // app-horizontal-menu, mat-icon, mat-chip{
  //   display: none;
  // }
}
   // end
#rangetemplate {
  .mat-dialog-container {
    padding:0!important;
  }

  .mat-dialog-title {
    padding:10px;
    font-size: 16px;
    font-weight: bold;
  }
}
.customPopup {
  .mat-dialog-container {
    padding:0;
  }
  .mat-dialog-title {
    padding:10px 15px;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    margin:0;
  }
  .mat-dialog-content {
    margin: 0;
    padding:10px 15px;
  }
  .f-12 {
    font-size: 12px;
  }
  .mat-dialog-actions {
    border-top: 1px solid #ddd;
    padding: 10px 15px;
    margin:0;
  }
}
.hideError {
  .dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container:after {
      visibility: hidden;
  }
}  
.showError {
  .dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container:after {
      visibility: visible;
  }
}  

input:disabled {
	background-color: #fff!important;
}
.dx-state-disabled .dx-widget, .dx-state-disabled.dx-widget {
  opacity: inherit!important;
  }
.bold {
  .dx-treelist-text-content {
    font-weight: bold;
  }
 
}

.ng-select.ng-select-searchable .ng-select-container .ng-value-container .ng-input {
  opacity: 0;
}
.ng-select .ng-clear-wrapper {
  width: 11px!important;
}

.ng-select {
  input:disabled {
    background-color: transparent!important;
  }
  .k-combobox {
    width: 100%!important;
  }
}

.k-text-selection ::selection, .k-block ::selection, .k-panel ::selection, .k-pane-wrapper ::selection, .k-view ::selection, .k-progressbar ::selection, .k-numerictextbox ::selection, .k-flatcolorpicker ::selection, .k-combobox ::selection, .k-datepicker ::selection, .k-timepicker ::selection, .k-datetimepicker ::selection, .k-dateinput ::selection, .k-dropdown ::selection, .k-dropdowntree ::selection, .k-multiselect ::selection, .k-maskedtextbox ::selection, .k-rating ::selection, .k-window ::selection, .k-drawer ::selection, .k-notification ::selection, .k-breadcrumb ::selection, .k-pager-wrap ::selection, .k-panelbar ::selection, .k-card ::selection, .k-splitter ::selection, .k-spreadsheet ::selection, .k-scheduler ::selection, .k-chat ::selection, .k-mediaplayer ::selection, .k-pdf-viewer ::selection, kendo-scrollview.k-scrollview-wrap ::selection, kendo-scrollview.k-scrollview ::selection, div.k-scrollview ::selection, .k-chart ::selection, .k-sparkline ::selection, .k-stockchart ::selection {
  background-color: #337ab7!important;
  color: #ffffff!important;
}
.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected {
  background-color: #337ab7 !important;
  color: #ffffff!important;
}
.dx-texteditor-input {
  min-height: 29px!important;
  padding: 5px 9px 8px!important;
}

  .f-14 {
    font-size: 14px;
  }
 
  #frame-monitor {
    .dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
        font-size: 16px!important;
    }
} 
#fitting-order-wrapper > app-panel > .panel-body {
  padding-bottom: 0;
}
#spt-order-wrapper > app-panel > .panel-body {
  padding-bottom: 0;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.h-450 {
  height: 450px;
}

.dx-scrollbar-horizontal .dx-scrollable-scroll, .dx-scrollbar-horizontal.dx-scrollbar-hoverable {
  height: 12px!important;
}

.app.indigo-light .mat-button.mat-secondry, .app.indigo-light .mat-icon-button.mat-secondry, .app.indigo-light .mat-stroked-button.mat-secondry {
  color: #228d50;
}
.full-width-dialog .mat-dialog-container {
  max-width: 100vw !important;
}
.position-relative {
  position: relative!important;
}

// .dx-scrollable-content .dx-datagrid-content table.dx-datagrid-table {
//   border-collapse: separate!important;
//   border-spacing: 0px 1em!important;
// }
.k-state-selected {
  background: #008000!important;
  font-weight: bold;
  color: white!important;
}
.k-item.k-state-selected, .k-list-optionlabel.k-state-selected {
  background-color: #337ab7 !important;
  color: #ffffff!important;
  input {
    background-color: #337ab7 !important;
  color: #ffffff!important;
  }
}
.hightLight {
  kendo-label > .k-label {
    font-weight: #e2e3ed;
  }
  .k-combobox .k-dropdown-wrap {
    background: #e2e3ed!important;
    font-weight: bold;
  }
  .dx-dropdowneditor-input-wrapper .dx-texteditor-input {
    background: #e2e3ed;
}
  .dx-texteditor-buttons-container {
    background: #f1f1f1;
  }
  .k-input {
    background: #e2e3ed;
  }
}
.app.indigo-light .mat-button.mat-primary[disabled], .app.indigo-light .mat-button.mat-accent[disabled], .app.indigo-light .mat-button.mat-warn[disabled], .app.indigo-light .mat-button[disabled][disabled], .app.indigo-light .mat-icon-button.mat-primary[disabled], .app.indigo-light .mat-icon-button.mat-accent[disabled], .app.indigo-light .mat-icon-button.mat-warn[disabled], .app.indigo-light .mat-icon-button[disabled][disabled], .app.indigo-light .mat-stroked-button.mat-primary[disabled], .app.indigo-light .mat-stroked-button.mat-accent[disabled], .app.indigo-light .mat-stroked-button.mat-warn[disabled], .app.indigo-light .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
  opacity: 0.6;
}
.arbic {
  .dx-field {
    font-size: 16px!important;
  }
  .dx-field input {
      text-align: right!important;
  }
  input {
    text-align: right!important;
}
}
.w-50-per {
  width:50px;
}
.kendo-input-center input {
  text-align: center!important;
}
.f-17 {
  font-size: 17px!important;
  font-weight: bold;
}

.bg-green .k-dropdown-wrap {
  background-color: lightgreen!important;
}

.bg-red .k-dropdown-wrap {
  background-color: red!important;
  color: white !important;
}

#salesInquiryWrapper, #productionInquiryWrapper, #productionReportWrapper {
  .dx-texteditor-input, .dx-field input, .dx-field textarea {
    font-weight: bold!important;
  }

}

.k-combobox .k-i-loading {
  height: 20px!important;
}
.float-left {
  float:left;
}
.float-right {
  float: right;
}
.mr-3, .mx-3 {
    margin-right: 1rem !important;
}
.ml-3, .mx-3 {
  margin-left: 1rem !important;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}
.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}
.mt-2, .mx-2 {
  margin-top: 0.5rem !important;
}
.mb-2, .mx-2 {
  margin-bottom: 0.5rem !important;
} 
.upload-btn-wrapper {
  position: relative;
}

.dx-tabpanel>.dx-tabpanel-tabs .dx-tab.dx-tab-active .dx-tab-content, .dx-tabpanel>.dx-tabpanel-tabs .dx-tab.dx-tab-selected .dx-tab-content {
  color: #fff!important;
}
.dx-tabpanel>.dx-tabpanel-tabs .dx-tab.dx-state-hover .dx-tab-content{
  color: #fff!important;
}
.border-0, td.border-0{
  border: 0 !important;
}
#stock-issuance-page-wrapper {
  .dx-datagrid .dx-row > td {
    padding: 3px 7px;
}
}
#invoiceGrid {
  height: 600px;
}
.processwidth {
  width: 20px;
}
.clearfix {
  clear: both!important;
}
.text-blue {
  color: #337ab7; 
}
.k-calendar .k-calendar-td.k-state-selected .k-link {
  background-color: #337ab7!important;
}
.overlayItemUpdate {
  position: absolute; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: not-allowed; /* Add a pointer on hover */
}

.swal2-container {
  z-index: 9999!important;
}

.dx-footer {
  background-color: #fff;
}
#list-container {
    .dx-list-item {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
    .dx-list-item:last-child {
      border-bottom: 1px solid #ddd;
    }

  }
.simulation-dialog {
  padding:0;
  .mat-dialog-container {
    padding: 0;
  }
  .mat-dialog-title {
    border-bottom: 1px solid #ddd;
    padding: 15px 15px;
  }
  .mat-dialog-content {
    margin: 0;
  }
  .mat-dialog-actions {
    padding: 15px 15px;
    border-top: 1px solid #ddd;
    display: block;
  }
}


#dx-grid {
  table tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  table tbody td {
    position: relative;
    overflow: inherit !important;
  }
  table tbody td:hover:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: -9999px;
    bottom: -9999px;
    background-color: rgb(215 206 206 / 20%);
    z-index: 1;
  }
  .upload-csv {
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  color: #424242;
  background-color: #f0f0f0;
}

#dropdownForDelete {
  width: 75%;
  padding: 10px;
  border-color: #545454;
  border-radius: 5px;
}

.bg-match {
  background: #1a237e!important;
  color: #fff!important;
  cursor: pointer;
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label, .mdc-evolution-chip__text-label {
    color: #fff!important;
    align-items: center;
    display: flex;
    justify-content: center;
}

}
.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  font-size: 22px;
    margin-top: 12px!important;
    margin-left: 11px;
    margin-bottom: 10px;
}
.advanced-pie-legend .total-value {
  margin-bottom: 10px;
}
.mat-mdc-icon-button.mat-mdc-button-base {
padding: 0!important;
--mdc-icon-button-state-layer-size: 30px!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// .full-screen-modal {
//   position: relative;
//   overflow: auto;
//   width: 100%;
//   height: 100%;

//   @media (max-width: 568px) {
//     width: 440px;
//   }

//   @media (max-width: 468px) {
//     width: 340px;
//   }

//   @media (max-width: 368px) {
//     width: 300px;
//   }
// }

.full-screen-modal .mat-dialog-container {
  max-width: 100vw !important;
  max-height: 100vh !important;
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.full-screen-dialog {
  width: 100%;
  height: 100%;
  padding: 0;
}

.dx-tab.dx-tab-selected .dx-icon, .dx-tab.dx-tab-selected .dx-tab-text {
  color: #fff;
}

.dx-tab.dx-state-hover {
  .dx-tab-text {
    color: #fff!important;
  }
}

.bg-grand-total {
  background-color: #D6DCE4!important; /* Example color: green */
  color: #333!important;
  border-color: #6e7989 !important;
}

.bg-customerOrder {
  background-color: yellow!important; /* Example color: green */
  color: #000!important;
  border-color: #000 !important;
}
#inventory-analytics {
  .dx-datagrid-rowsview .dx-row {
    border-color: #ddd !important;
  }
  .dx-datagrid-rowsview div.dx-row {
    border: none;
  }
}
.mat-mdc-dialog-container .mdc-dialog__title {
  border-bottom: 1px solid #dedede;
}
.mat-mdc-dialog-actions {
  border-top: 1px solid #dedede;
}

#finish-stock-container {
  .dx-datagrid-rowsview .dx-row {
    border-color: #ddd !important;
  }
  .dx-datagrid-rowsview div.dx-row {
    border: none;
  }
}

#customer-grid {
  .dx-header-row > td {
    background-color: #70c92f!important;
  }
}
// .dx-datagrid .dx-column-lines > td.bg-grand-total {
//   border-bottom: 1px solid #6e7989 !important;
// }
// .dx-datagrid .dx-datagrid-headers .dx-header-filter, .dx-datagrid .dx-datagrid-headers .dx-header-row > td.bg-grand-total {
//   border-top: 1px solid #333;
//   border-left: 1px solid #333;
//   border-right: 1px solid #333;
// }